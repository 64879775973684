import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  doc,
  getDoc,
  limit,
  orderBy,
} from "firebase/firestore";
import app from "../firebase";
import { clearLoginData, getFirstCountry } from "./store";
import { getAuthorizationHeaderWithContentType } from "./utils";

const fireStoreDB = getFirestore(app);

export const fetchAllApprovedSuppliersFromFirebase = async (
  searchTerm = ""
) => {
  try {
    let qry;
    // searchTerm = "Ub";
    if (searchTerm.trim() !== "") {
      // Query that searches for suppliers by name based on the searchTerm

      qry = query(
        collection(fireStoreDB, "Suppliers"),
        where("status", "==", "approved"),
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "\uf8ff"),
        orderBy("name"),
        limit(10) // Limit the number of documents to 10
      );
    } else {
      // Default query when there's no search term
      qry = query(
        collection(fireStoreDB, "Suppliers"),
        where("status", "==", "approved"),
        orderBy("name"),
        limit(10) // Limit the number of documents to 10
      );
    }

    let querySnapshot = await getDocs(qry);

    if (!querySnapshot.empty) {
      const suppliers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return suppliers;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchSuppliersFromFirebaseByNameSearch = async (
  searchTerm = ""
) => {
  try {
    let qry;
    // searchTerm = "Ub";
    if (searchTerm.trim() !== "") {
      // Query that searches for suppliers by name based on the searchTerm

      qry = query(
        collection(fireStoreDB, "Suppliers"),
        // where("status", "==", "approved"),
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "\uf8ff"),
        orderBy("name"),
        limit(1000) // Limit the number of documents to 10
      );
    } else {
      // Default query when there's no search term
      qry = query(
        collection(fireStoreDB, "Suppliers"),
        // where("status", "==", "approved"),
        orderBy("name"),
        limit(1000) // Limit the number of documents to 10
      );
    }

    let querySnapshot = await getDocs(qry);

    if (!querySnapshot.empty) {
      const suppliers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return suppliers;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchSuppliersFromFirebaseByName = async (name) => {
  try {
    const qry = query(
      collection(fireStoreDB, "Suppliers"),
      where("status", "==", "approved"),
      where("name", "==", name)
    );

    let querySnapshot = await getDocs(qry);

    // Check if documents exist
    if (!querySnapshot.empty) {
      const firstDoc = querySnapshot.docs[0].data();
      return {
        id: querySnapshot.docs[0].id,
        ...firstDoc,
      };
    } else {
      return null; // Or appropriate value for no documents found
    }
  } catch (error) {
    console.error(error);
    return null; // Or handle the error as needed
  }
};

export const fetchSupplierFromFirebaseById = async (supplierId) => {
  try {
    const docRef = doc(fireStoreDB, "Suppliers", supplierId);
    let docSnapshot = await getDoc(docRef);

    // Check if document exists
    // if (docSnapshot.exists() && docSnapshot.data().status === "approved") {
    if (docSnapshot.exists()) {
      const docData = docSnapshot.data();
      return {
        id: docSnapshot.id,
        ...docData,
      };
    } else {
      return null; // Or appropriate value for no document found
    }
  } catch (error) {
    console.error(error);
    return null; // Or handle the error as needed
  }
};

export const fetchContactFromFirebaseById = async (
  businessId,
  dataSource,
  contactId
) => {
  try {
    const docRef = doc(
      fireStoreDB,
      "Contacts",
      businessId,
      dataSource,
      contactId
    );
    let docSnapshot = await getDoc(docRef);

    // Check if document exists
    if (docSnapshot.exists()) {
      const docData = docSnapshot.data();
      return {
        id: docSnapshot.id,
        ...docData,
      };
    } else {
      return null; // Or appropriate value for no document found
    }
  } catch (error) {
    console.error(error);
    return null; // Or handle the error as needed
  }
};

// Updates the supplier ID and score of contact in the Contacts collection
export const updateContactWithSupplierIdAndScore = async (
  businessId,
  dataSource,
  contactId,
  supplierId
) => {
  try {
    const docRef = doc(
      fireStoreDB,
      "Contacts",
      businessId,
      dataSource,
      contactId
    );
    let docSnapshot = await getDoc(docRef);

    // Check if document exists
    if (docSnapshot.exists()) {
      // Update the document with new supplierId
      await updateDoc(docRef, {
        supplierId: supplierId, // Updating supplierId field with the new value
        score: 1, // Updating score field with the new value
      });

      // Optionally, fetch the updated document to return its updated state
      docSnapshot = await getDoc(docRef);
      const updatedDocData = docSnapshot.data();

      return {
        id: docSnapshot.id,
        ...updatedDocData,
      };
    } else {
      console.log("Document does not exist!");
      return null; // Or appropriate value for no document found
    }
  } catch (error) {
    console.error(error);
    return null; // Or handle the error as needed
  }
};

export const getSupplier = async (supplierId) => {
  try {
    // Correctly target the document by its ID within the Suppliers collection
    const docRef = doc(fireStoreDB, "Suppliers", supplierId);
    let docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const docData = docSnapshot.data();
      return {
        id: docSnapshot.id,
        ...docData,
      };
    } else {
      console.log("Document does not exist!");
      return null; // Or appropriate value for no document found
    }
  } catch (error) {
    console.error(error);
    return null; // Or handle the error as needed
  }
};

export const updateSupplier = async (supplier) => {
  try {
    // Correctly target the document by its ID within the Suppliers collection
    const docRef = doc(fireStoreDB, "Suppliers", supplier.id);
    let docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Update the document with the new supplier data
      await updateDoc(docRef, {
        website: supplier.website,
        email: supplier.email,
        country: supplier.country,
        primaryCategory: supplier.primaryCategory,
        secondaryCategory: supplier.secondaryCategory,
        primaryEmissionFactor: supplier.primaryEmissionFactor,
        secondaryEmissionFactor: supplier.secondaryEmissionFactor,
      });
      console.log("Supplier updated successfully");
      return true;
    } else {
      console.log("Document does not exist!");
      return null; // Or appropriate value for no document found
    }
  } catch (error) {
    console.error("Error updating document: ", error);
    return null; // Or handle the error as needed
  }
};

export const fetchNetnadaFactorsFromMongoDB = async () => {
  const contryName = getFirstCountry();
  const targetUrl = `https://netnada-factor-production-8ed52ccdfd5e.herokuapp.com/factors/allGroupByCategoryAndCountry/${contryName}`;
  try {
    const response = await fetch(targetUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_NETNADA_API_KEY,
      },
    });
    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    const data = await response.json();
    // console.log("data", data);

    return data;
  } catch (error) {
    console.error("Failed to fetch emission factors:", error);
    return null;
  }
};

export const fetchNetnadaFactorsFromMongoDBOpenSearch = async (params) => {
  // Construct the query string from the parameters object
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.set(key, params[key]);
    }
  }

  // Encode the complete query string
  const queryString = queryParams.toString();
  // console.log("queryString", queryString);

  // Construct the URL with the encoded query parameters
  const targetUrl = `https://netnada-factor-production-8ed52ccdfd5e.herokuapp.com/factors/openSearch?${queryString}`;

  try {
    const response = await fetch(targetUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_NETNADA_API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch emission factors:", error);
    return null;
  }
};

// export const fetchNetnadaFactorsFromMongoDBOpenSearch = async (
//   categoryName,
//   activityName
// ) => {
//   // Encode the parameters to ensure they are properly escaped for use in a URL
//   const encodedCategoryName = encodeURIComponent(categoryName);
//   const encodedActivityName = encodeURIComponent(activityName);

//   // Construct the URL with the encoded query parameters
//   const targetUrl = `https://netnada-factor-production-8ed52ccdfd5e.herokuapp.com/factors/openSearch?categoryName=${encodedCategoryName}&activityName=${encodedActivityName}`;

//   try {
//     const response = await fetch(targetUrl, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "x-api-key": process.env.REACT_APP_NETNADA_API_KEY,
//       },
//     });
//     if (!response.ok) {
//       throw new Error("Something went wrong");
//     }

//     const data = await response.json();
//     // console.log("data", data);

//     return data;
//   } catch (error) {
//     console.error("Failed to fetch emission factors:", error);
//     return null;
//   }
// };

export const createCustomEmissionFactor = async (params) => {
  // Construct the query string from the parameters object
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.set(key, params[key]);
    }
  }

  // Encode the complete query string
  const queryString = queryParams.toString();
  // console.log("queryString", queryString);

  // Construct the URL with the encoded query parameters
  const targetUrl = `https://netnada-factor-production-8ed52ccdfd5e.herokuapp.com/factors/createCustomFactor?${queryString}`;

  try {
    const response = await fetch(targetUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_NETNADA_API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to create a new emission factor:", error);
    return null;
  }
};

//group updated unified data before saving to GroupedUnifiedData collection
// export const groupUnifiedData = async (data) => {
//   // console.log("data=>", data)
//   //Step 1. grouping within unified data list by contactName,date,primaryCategory,secondaryCategory,emissions and represent as a single entry
//   const totalEmissionsList = data.reduce((acc, cur) => {
//     const {
//       date,
//       accountName,
//       consumption,
//       contactId,
//       contactName,
//       dataSource,
//       scope,
//       primaryCategory,
//       secondaryCategory,
//       officeId,
//       emissions,
//       categoryMatchingSource,
//       score,
//       primaryEmissionFactor,
//       secondaryEmissionFactor,
//     } = cur;

//     // Update the date to the first day of the month (01)
//     const updatedDate = `${date.substring(0, 7)}-01`;
//     const key = `${updatedDate},${contactName},${scope},${primaryCategory},${secondaryCategory},${officeId}`;

//     if (acc[key]) {
//       acc[key].emissions += emissions;
//     } else {
//       acc[key] = {
//         date: new Date(updatedDate).toISOString(),
//         accountName,
//         consumption,
//         contactId,
//         contactName,
//         dataSource,
//         scope,
//         primaryCategory,
//         secondaryCategory,
//         officeId,
//         emissions,
//         categoryMatchingSource,
//         score,
//         primaryEmissionFactor,
//         secondaryEmissionFactor,
//       };
//     }
//     return acc;
//   }, {});
//   // console.log("totalEmissionsList->", totalEmissionsList);

//   //Step 2. convert totalEmissionsList to format {Date: "2020-01-01", SecondaryCategory:"Municipal", Emissions: 100, PrimaryEmissionFactor: 100, SecondaryEmissionFactor: 100, EmissionFactorId: "65a08a46de4bbfda2171f1db"}
//   const groupedData = Object.keys(totalEmissionsList).map((key) => {
//     const keyArray = key.split(",");
//     return {
//       date: keyArray[0],
//       contactName: keyArray[1],
//       scope: keyArray[2],
//       consumption: totalEmissionsList[key].consumption,
//       dataSource: totalEmissionsList[key].dataSource,
//       accountName: totalEmissionsList[key].accountName,
//       primaryCategory: totalEmissionsList[key].primaryCategory,
//       secondaryCategory: totalEmissionsList[key].secondaryCategory,
//       officeId: totalEmissionsList[key].officeId,
//       totalEmissions: totalEmissionsList[key].emissions,
//       contactId: totalEmissionsList[key].contactId,
//       categoryMatchingSource: totalEmissionsList[key].categoryMatchingSource,
//       score: totalEmissionsList[key].score,
//       primaryEmissionFactor: totalEmissionsList[key].primaryEmissionFactor,
//       secondaryEmissionFactor: totalEmissionsList[key].secondaryEmissionFactor,
//     };
//   });
//   // console.log("groupedData->", groupedData);

//   return groupedData;
// };

export const groupUnifiedData = async (data) => {
  console.log("groupUnifiedData called");
  console.log("data:", data);
  //Step 1. grouping within unified data list by contactName,date,primaryCategory,secondaryCategory,emissions and represent as a single entry
  const totalEmissionsList = data.reduce((acc, cur) => {
    const {
      date,
      accountName,
      consumption,
      contactId,
      contactName,
      dataSource,
      scope,
      primaryCategory,
      secondaryCategory,
      officeId,
      emissions,
      categoryMatchingSource,
      score,
      primaryEmissionFactor,
      secondaryEmissionFactor,
    } = cur;
    // Update the date to the first day of the month (01)
    const updatedDate = `${date.substring(0, 7)}-01`;
    const key = `${updatedDate},${contactId},${contactName},${scope},${primaryCategory},${secondaryCategory},${officeId},${categoryMatchingSource},${score},${accountName},${dataSource}`;
    // console.log("key->", key);
    if (acc[key]) {
      acc[key].emissions += emissions;
    } else {
      acc[key] = {
        date: new Date(updatedDate).toISOString(),
        accountName,
        consumption,
        contactId,
        contactName,
        dataSource,
        scope,
        primaryCategory,
        secondaryCategory,
        officeId,
        emissions,
        categoryMatchingSource,
        score,
        primaryEmissionFactor,
        secondaryEmissionFactor,
      };
    }
    return acc;
  }, {});
  // console.log("totalEmissionsList->", totalEmissionsList);
  //Step 2. convert totalEmissionsList to format {Date: "2020-01-01", SecondaryCategory:"Municipal", Emissions: 100, PrimaryEmissionFactor: 100, SecondaryEmissionFactor: 100, EmissionFactorId: "65a08a46de4bbfda2171f1db"}
  const groupedData = Object.keys(totalEmissionsList).map((key) => {
    const keyArray = key.split(",");
    return {
      date: keyArray[0],
      contactName: totalEmissionsList[key].contactName,
      scope: totalEmissionsList[key].scope,
      consumption: totalEmissionsList[key].consumption,
      dataSource: totalEmissionsList[key].dataSource,
      accountName: totalEmissionsList[key].accountName,
      primaryCategory: totalEmissionsList[key].primaryCategory,
      secondaryCategory: totalEmissionsList[key].secondaryCategory,
      officeId: totalEmissionsList[key].officeId,
      totalEmissions: totalEmissionsList[key].emissions,
      contactId: totalEmissionsList[key].contactId,
      categoryMatchingSource: totalEmissionsList[key].categoryMatchingSource,
      score: totalEmissionsList[key].score,
      primaryEmissionFactor: totalEmissionsList[key].primaryEmissionFactor,
      secondaryEmissionFactor: totalEmissionsList[key].secondaryEmissionFactor,
    };
  });

  console.log("groupedData->", groupedData);
  return groupedData;
};

export const sendInviteEmailToSupplier = async (body) => {
  try {
    const response = await fetch("/api/users/supplier-invite", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const message = await response.text();
      console.log("Message: ", message);
      return message;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaignURL = async (
  supplierId,
  supplierName,
  reportingYear,
  invitedBy
) => {
  try {
    const encodedSupplierName = encodeURIComponent(supplierName);
    const response = await fetch(
      `/api/users/get-campaign-URL/${supplierId}/${encodedSupplierName}/${reportingYear}/${invitedBy}`,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const fetchTransparencyAssessmentBySupplierId = async (supplierId) => {
  try {
    const documentRef = doc(fireStoreDB, "TransparencyAssessment", supplierId);
    const documentSnapshot = await getDoc(documentRef);

    if (!documentSnapshot.exists()) {
      console.log("No such document!");
      return null;
    }

    const subcollectionRef = collection(documentRef, "Surveys");
    const subcollectionSnapshot = await getDocs(subcollectionRef);
    const surveys = [];
    subcollectionSnapshot.forEach((docSnapshot) => {
      surveys.push({ id: docSnapshot.id, ...docSnapshot.data() });
    });
    const combinedData = {
      id: documentSnapshot.id,
      ...documentSnapshot.data(),
      surveys: surveys,
    };

    return combinedData;
  } catch (error) {
    console.error("Error fetching document with subcollection: ", error);
    throw error;
  }
};

export const fetchAllTransparencyAssessmentsWithLatestSurvey = async () => {
  try {
    const collectionRef = collection(fireStoreDB, "TransparencyAssessment");
    const collectionSnapshot = await getDocs(collectionRef);
    const assessments = [];

    for (const docSnapshot of collectionSnapshot.docs) {
      const documentRef = doc(
        fireStoreDB,
        "TransparencyAssessment",
        docSnapshot.id
      );
      const subcollectionRef = collection(documentRef, "Surveys");
      const subcollectionSnapshot = await getDocs(subcollectionRef);
      const surveys = [];

      subcollectionSnapshot.forEach((subDocSnapshot) => {
        surveys.push({ id: subDocSnapshot.id, ...subDocSnapshot.data() });
      });

      // Sort surveys by reportingYear descending and take the first one
      const latestSurvey = surveys
        .slice()
        .sort((a, b) => b.reportingYear.localeCompare(a.reportingYear))[0];

      assessments.push({
        id: docSnapshot.id,
        ...docSnapshot.data(),
        latestSurvey: latestSurvey,
      });
    }

    return assessments;
  } catch (error) {
    console.error(
      "Error fetching transparency assessments with latest survey: ",
      error
    );
    throw error;
  }
};
