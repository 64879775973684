import React, { useEffect, useMemo } from "react";
import { Spinner, Form } from "react-bootstrap";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  query,
  updateDoc,
  where,
  deleteDoc,
  getDoc,
  writeBatch,
  addDoc,
} from "firebase/firestore";
import app from "../../../firebase";
import { toast } from "react-toastify";
import {
  getActiveUserData,
  getFirstCountry,
  getUserData,
  isDataFrozen,
  isImpersonationModeEnabled,
} from "../../../helpers/store";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../../helpers/unifiedDataHelper";
import { formatDateToYYYYMMDD } from "../../../helpers/utils";
import FrozenDataModal from "../../utilities/FrozenDataModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import AllTransactionsHeader from "./AllTransactionsTableHeader";
import FileViewModal from "./FileViewModal";
import EditSelectedAccountNamesModal from "./EditSelectedAccountNamesModal";
import WarningModal from "./WarningModal";
import { applyFilters } from "../../../helpers/filterUtils";
import FilterBox from "../../common/FilterBox";
import EditableCell from "./EditableCell";

const fireStoreDB = getFirestore(app);

const EXCLUDE_ACCOUNT_TYPES_XERO = ["EQUITY", "REVENUE"];

const AllTransactionsTable = ({ startDate, endDate }) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isLoading, setIsLoading] = useState(true);
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );
  const [unifiedData, setUnifiedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const [combinedCategoryFactorsObj, setCombinedCategoryFactorsObj] = useState(
    {}
  );
  const [accountsList, setAccountsList] = useState([]);
  const [
    showEditSelectedAccountNamesModal,
    setShowEditSelectedAccountNamesModal,
  ] = useState(false);
  const [showFrozenDataModal, setShowFrozenDataModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [billUrl, setBillUrl] = useState("");
  const [billId, setBillId] = useState("");

  // New state for Filter and Search Functionality
  const [searchBy, setSearchBy] = useState("Contact Name");
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({});

  const fetchBillUrl = async (mainId) => {
    try {
      const docRef = doc(fireStoreDB, "Files", mainId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const url = docSnap.data().url;
        setBillUrl(url);
        setBillId(mainId);
        setShowBillModal(true);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching bill URL:", error);
    }
  };

  const isAllRowsSelected =
    Object.keys(selectedRowIds).length === unifiedData.length &&
    unifiedData.length > 0;

  const handleHeaderCheckboxChange = () => {
    if (isAllRowsSelected) {
      setSelectedRowIds({}); // Deselect all rows
    } else {
      const newSelectedRowIds = {};
      unifiedData.forEach((row, index) => {
        newSelectedRowIds[index] = true;
      });
      setSelectedRowIds(newSelectedRowIds); // Select all rows
    }
  };

  const handleRowSelection = (row, e) => {
    e.stopPropagation(); // Prevent row click when selecting the checkbox

    const newSelectedRowIds = { ...selectedRowIds };
    if (newSelectedRowIds[row.index]) {
      delete newSelectedRowIds[row.index]; // Deselect the row if it's already selected
    } else {
      newSelectedRowIds[row.index] = true; // Select the row
    }

    setSelectedRowIds(newSelectedRowIds); // Update the selectedRowIds state
  };

  const [isEditMode, setIsEditMode] = useState(false); // Manage the view/edit mode state
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Track unsaved changes
  const [showWarningModal, setShowWarningModal] = useState(false);

  // Function to close the modal
  const handleClose = () => setShowWarningModal(false);

  const saveChanges = async () => {
    const changedData = unifiedData.filter((row) => row._changed); // Filter only changed rows

    try {
      // Loop through each changed row and save the changes
      await Promise.all(
        changedData.map(async (row) => {
          // Track old values for editable columns
          const oldPrimaryCategory = row.oldPrimaryCategory;
          const oldSecondaryCategory = row.oldSecondaryCategory;
          const oldScope = row.oldScope;
          const oldDataSource = row.oldDataSource;
          const oldInclusionStatus = row.oldInclusionStatus;

          const isInclusionStatusChanged =
            row.inclusionStatus !== oldInclusionStatus;
          const isCategoryChanged =
            row.primaryCategory !== oldPrimaryCategory ||
            row.secondaryCategory !== oldSecondaryCategory;
          const isScopeOrDataSourceChanged =
            row.scope !== oldScope || row.dataSource !== oldDataSource;

          // Save changes to UnifiedData
          const docRef = doc(
            fireStoreDB,
            "UnifiedData",
            activeUser.businessId,
            "DataLines",
            row.id
          );

          const dataToBeSaved = {
            emissions: row.emissions,
            description: row.description,
            primaryCategory: row.primaryCategory,
            secondaryCategory: row.secondaryCategory,
            dataSource: row.dataSource,
            scope: row.scope,
            inclusionStatus: row.inclusionStatus,
            reason: row.reason,
            categoryMatchingSource: row.categoryMatchingSource,
            primaryEmissionFactor: row.primaryEmissionFactor,
            secondaryEmissionFactor: row.secondaryEmissionFactor,
            emissionFactorId: row.emissionFactorId,
            score: row.score,
          };

          await updateDoc(docRef, dataToBeSaved); // Save each changed row

          const isExcluded = row.inclusionStatus === "EXCLUDED";

          // Handle inclusion/exclusion first
          if (isInclusionStatusChanged) {
            await updateGroupedUnifiedData(row, isExcluded); // Call your existing function to handle inclusion/exclusion
          }

          if (isExcluded) {
            return; // Skip further processing for excluded rows
          }

          // If categories, scope, or dataSource changed, decrement old emissions and increment new ones
          if (isCategoryChanged || isScopeOrDataSourceChanged) {
            await updateGroupedUnifiedDataByContactName(
              row,
              true,
              oldPrimaryCategory,
              oldSecondaryCategory,
              oldScope,
              oldDataSource
            );
          }
        })
      );

      toast.success("Changes saved successfully!");

      // Mark rows as saved
      setUnifiedData((prevData) =>
        prevData.map((row) => ({ ...row, _changed: false }))
      );
      setUnsavedChanges(false); // No unsaved changes
      setIsEditMode(false); // Exit edit mode
    } catch (error) {
      toast.error("Error saving changes");
      console.error(error);
    }
  };

  // Handle saving changes
  const handleSaveChanges = () => {
    // Call your save function here to persist changes
    saveChanges();
    setIsEditMode(false); // Switch to View Mode after saving
    setUnsavedChanges(false); // Reset unsaved changes
    handleClose(); // Close the modal
  };

  // Handle switching to view mode without saving
  const handleDiscardChanges = () => {
    // Reset unsaved changes and revert the modified data
    setUnifiedData((prevUnifiedData) =>
      prevUnifiedData.map((row) => {
        if (row._changed) {
          return {
            ...row,
            emissions: row.oldEmissions, // Revert to old emissions
            primaryCategory: row.oldPrimaryCategory, // Revert to old if it exists
            secondaryCategory: row.oldSecondaryCategory,
            scope: row.oldScope,
            dataSource: row.oldDataSource,
            inclusionStatus: row.oldInclusionStatus,
            _changed: false, // Reset change flag
            oldValuesTracked: false, // Reset old values tracking
          };
        }
        return row;
      })
    );

    // Reset unsaved changes and exit edit mode
    setUnsavedChanges(false); // No unsaved changes
    setIsEditMode(false); // Switch back to view mode
    handleClose(); // Close the modal
  };

  const toggleEditMode = () => {
    if (isEditMode && unsavedChanges) {
      setShowWarningModal(true); // Show modal if there are unsaved changes
      return; // Do not switch to View Mode yet
    }

    setIsEditMode((prevMode) => !prevMode); // Toggle between edit and view modes
  };

  // Function to populate the secondary categories based on the primary category
  const toSetSecondaryCategoriesByPrimaryCategory = (primaryCategory) => {
    const reportingYear = parseInt(activeUser.selectedMeasurementYear);
    if (primaryCategory) {
      let categories = combinedCategoryFactorsObj[primaryCategory];

      // Group by unit
      const groupedByUnit = categories.reduce((acc, item) => {
        // If the unit doesn't exist in the accumulator, add it as a key with an empty array
        if (!acc[item.unit]) {
          acc[item.unit] = [];
        }

        // Push the current item to the corresponding unit array
        acc[item.unit].push(item);
        return acc;
      }, {});
      // console.log("groupedByUnit=>", groupedByUnit);

      let secondaryCategories = [];

      //if the primary category is 'Electricity', then filter only unit 'kwh' for the year nearest to reporting year
      if (primaryCategory === "Electricity") {
        secondaryCategories = Object.keys(groupedByUnit)
          .filter((unit) => unit === "kwh")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit],
          }));

        //find the categories whose year is nearest to reporting year
        let categories = secondaryCategories[0].categories;

        //sort the categories by year in descending order
        categories.sort((a, b) => parseInt(b.year) - parseInt(a.year));

        //fetch unique years
        const uniqueYears = [
          ...new Set(categories.map((item) => item.year)),
        ].sort((a, b) => b - a);

        let nearestLowerYear = null;
        //compare the unique years with reporting year and get the nearest lower year
        for (let i = 0; i < uniqueYears.length; i++) {
          if (uniqueYears[i] <= reportingYear) {
            nearestLowerYear = uniqueYears[i];
            break;
          }
        }
        // console.log("nearestLowerYear=>", nearestLowerYear);

        //filter the categories whose year is nearestLowerYear
        categories = categories.filter(
          (item) => item.year === nearestLowerYear
        );

        //update the secondaryCategories with the filtered categories
        secondaryCategories[0].categories = categories;

        // console.log("secondaryCategories=>", secondaryCategories);

        return secondaryCategories;
      } else if (primaryCategory === "Stationary energy (gaseous fuels)") {
        //if the primary category is 'Stationary energy (gaseous fuels)', then filter only unit 'GJ'

        secondaryCategories = Object.keys(groupedByUnit)
          .filter((unit) => unit === "GJ")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit].filter(
              (item) =>
                item.activity_name === "Natural Gas National average (GJ)"
            ),
          }));

        // console.log("secondaryCategories=>", secondaryCategories);

        return secondaryCategories;
      } else {
        //if others , then filter only unit '$'
        // Transform the grouped object back into an array suitable for the dropdown
        secondaryCategories = Object.keys(groupedByUnit)
          // .filter((unit) => unit === "$")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit],
          }));
      }

      // console.log("secondaryCategories=>", secondaryCategories);

      return secondaryCategories;
    }
  };

  // Function to update the data in the local state and track unsaved changes
  const updateMyData = (rowIndex, columnId, value) => {
    // Get the id of the row from filteredData
    const rowId = filteredData[rowIndex].id;

    setUnifiedData((prevUnifiedData) => {
      let isChanged = false;

      // Update the unifiedData (which is the main data set)
      const updatedUnifiedData = prevUnifiedData.map((row) => {
        // Match by the row id, instead of relying on index
        if (row.id === rowId) {
          if (row[columnId] !== value) {
            isChanged = true;

            // Check if old values have already been tracked
            if (!row.oldValuesTracked) {
              // Only store old values if they haven't been tracked before
              row.oldPrimaryCategory = row.primaryCategory;
              row.oldSecondaryCategory = row.secondaryCategory;
              row.oldScope = row.scope;
              row.oldDataSource = row.dataSource;
              row.oldInclusionStatus = row.inclusionStatus;
              row.oldValuesTracked = true; // Set the flag to true once old values are tracked
            }

            if (columnId === "primaryCategory") {
              // Handle Primary Category change, reset secondary category
              return {
                ...row,
                primaryCategory: value,
                secondaryCategory: "", // Reset secondary category when primary changes
                _changed: true, // Mark row as changed
              };
            }

            if (columnId === "secondaryCategory") {
              // Handle Secondary Category change
              return {
                ...row,
                secondaryCategory: value,
                _changed: true, // Mark row as changed
              };
            }

            // Handle other columns (scope, dataSource, inclusionStatus)
            return {
              ...row,
              [columnId]: value, // Update the respective field
              _changed: true, // Mark row as changed
            };
          }
        }
        return row;
      });

      if (isChanged) {
        setUnsavedChanges(true); // Mark unsaved changes

        // Update the filteredData to keep both in sync
        setFilteredData((prevFilteredData) => {
          const updatedFilteredData = prevFilteredData.map((row, index) => {
            if (index === rowIndex) {
              // Apply the same changes to filteredData
              return {
                ...row,
                [columnId]: value,
                _changed: true,
              };
            }
            return row;
          });
          return updatedFilteredData;
        });
      }

      return updatedUnifiedData; // Return updated unifiedData
    });
  };

  const handleSecondaryCategoryChange = (e, rowIndex, secondaryCategories) => {
    const selectedSecondaryCategoryId = e.target.value;

    // Find the selected secondary category object
    const selectedCategoryObj = secondaryCategories
      .flatMap((group) => group.categories)
      .find((cat) => cat._id === selectedSecondaryCategoryId);

    if (!selectedCategoryObj) {
      console.error("Selected secondary category not found");
      return;
    }

    // console.log("Secondary category change triggered for row index", rowIndex);
    // console.log("Selected secondary category:", selectedCategoryObj);

    // Recalculate emissions based on the selected category
    let factor = parseFloat(selectedCategoryObj.co2e_total);
    let emissions = 0.0;

    let item = unifiedData[rowIndex];

    // Calculate emissions based on the data source
    if (item.dataSource === "Bill") {
      if (item.accountName === "electricity") {
        const consumption = parseFloat(item.consumption);
        emissions = (consumption * factor) / 1000; // Convert to tons/kWh
      } else if (item.accountName === "gas") {
        const consumption = parseFloat(item.consumption);
        emissions = (consumption * factor) / (1000 * 1000); // Convert to tons/GJ
      }
    } else if (item.subTotal) {
      const subTotal = parseFloat(item.subTotal);
      emissions = (subTotal * factor) / 1000; // Convert to tons
    }

    // Use callback form of state updates to ensure values are updated in the correct order
    updateMyData(rowIndex, "categoryMatchingSource", "MANUAL");
    updateMyData(
      rowIndex,
      "primaryEmissionFactor",
      selectedCategoryObj.category_co2e_total
    );
    updateMyData(
      rowIndex,
      "secondaryCategory",
      selectedCategoryObj.activity_name
    );
    updateMyData(rowIndex, "emissions", emissions); // Update the calculated emissions
    updateMyData(rowIndex, "secondaryEmissionFactor", factor);
    updateMyData(rowIndex, "emissionFactorId", selectedCategoryObj._id);
    updateMyData(rowIndex, "scope", selectedCategoryObj.default_scope);
    updateMyData(rowIndex, "score", 1.0);

    console.log("Updated unified data for secondary category selection");
  };

  const handlePrimaryCategoryChange = (
    newPrimaryCategory,
    rowIndex,
    secondaryCategories,
    updateMyData
  ) => {
    // Get the first secondary category from the updated secondary options
    const firstSecondaryCategoryObj =
      secondaryCategories.length > 0 &&
      secondaryCategories[0].categories.length > 0
        ? secondaryCategories[0].categories[0] // Get the first category in the list
        : null;

    if (!firstSecondaryCategoryObj) {
      console.error(
        "No secondary category available for the selected primary category"
      );
      return;
    }

    // Recalculate emissions based on the selected first secondary category
    let factor = parseFloat(firstSecondaryCategoryObj.co2e_total);
    let emissions = 0.0;

    let item = unifiedData[rowIndex]; // Assuming unifiedData is in scope

    // Calculate emissions based on the data source
    if (item.dataSource === "Bill") {
      if (item.accountName === "electricity") {
        const consumption = parseFloat(item.consumption);
        emissions = (consumption * factor) / 1000; // Convert to tons/kWh
      } else if (item.accountName === "gas") {
        const consumption = parseFloat(item.consumption);
        emissions = (consumption * factor) / (1000 * 1000); // Convert to tons/GJ
      }
    } else if (item.subTotal) {
      const subTotal = parseFloat(item.subTotal);
      emissions = (subTotal * factor) / 1000; // Convert to tons
    }

    // Now update all fields similar to handleSecondaryCategoryChange
    updateMyData(rowIndex, "primaryCategory", newPrimaryCategory); // Update primary category
    updateMyData(
      rowIndex,
      "secondaryCategory",
      firstSecondaryCategoryObj.activity_name
    ); // Update with the first secondary category
    updateMyData(rowIndex, "categoryMatchingSource", "MANUAL"); // Set matching source to manual
    updateMyData(
      rowIndex,
      "primaryEmissionFactor",
      firstSecondaryCategoryObj.category_co2e_total
    ); // Update primary emission factor
    updateMyData(rowIndex, "secondaryEmissionFactor", factor); // Update secondary emission factor
    updateMyData(rowIndex, "emissionFactorId", firstSecondaryCategoryObj._id); // Update emission factor ID
    updateMyData(rowIndex, "scope", firstSecondaryCategoryObj.default_scope); // Update the scope
    updateMyData(rowIndex, "emissions", emissions); // Update calculated emissions
    updateMyData(rowIndex, "score", 1.0); // Update score

    console.log(
      "Updated data for primary and first secondary category selection"
    );
  };

  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: (
          <Form.Check
            type="checkbox"
            className="form-check fs-0 mb-0 d-flex align-items-center"
          >
            <Form.Check.Input
              type="checkbox"
              checked={isAllRowsSelected}
              onChange={handleHeaderCheckboxChange}
              ref={(element) => {
                if (element) {
                  element.indeterminate =
                    Object.keys(selectedRowIds).length > 0 &&
                    Object.keys(selectedRowIds).length < unifiedData.length;
                }
              }}
            />
          </Form.Check>
        ),
        Cell: ({ row }) => (
          <Form.Check
            type="checkbox"
            className="form-check fs-0 mb-0 d-flex align-items-center"
          >
            <Form.Check.Input
              type="checkbox"
              checked={selectedRowIds[row.index]}
              onClick={(e) => handleRowSelection(row, e)} // Use checkbox selection
            />
          </Form.Check>
        ),
        width: 10,
        disableFilters: true,
      },
      {
        accessor: "dateTime",
        Header: "Date",
        sortType: "datetime",
        // disableFilters: true, // Disable filter for date, or you can add a custom date filter
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Cell: ({ row }) => {
          return new Date(row.original.dateTime).toLocaleDateString("en-GB");
        },
        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "date", accessor: "dateTime" }}
            filters={filters}
            setFilters={setFilters}
          />
        ),
      },
      {
        accessor: "contactName",
        Header: "Contact Name",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "text", accessor: "contactName" }} // Add accessor for contactName
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "accountName",
        Header: "Account Name",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          className: "text-wrap",
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "text", accessor: "accountName" }} // Add accessor for accountName
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "accountType",
        Header: "Account Type",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          className: "text-wrap",
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "text", accessor: "accountType" }} // Add accessor for accountType
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "emissions",
        Header: "Emissions",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        sortType: "basic",
        Cell: (rowData) => {
          const value = rowData.row.original.emissions || 0;
          const shouldUseConciseFormat = value < 0.1;
          const formattedNumber = shouldUseConciseFormat
            ? value.toFixed(3)
            : value.toFixed(2);

          return (
            <div className="number-container" title={value}>
              {formattedNumber}
              {shouldUseConciseFormat && <span className="ellipsis">...</span>}
            </div>
          );
        },
        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "number", accessor: "emissions" }} // Add accessor for emissions
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "description",
        Header: "Description",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          className: "text-wrap",
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Cell: (props) => (
          <EditableCell
            {...props}
            rowIndex={props.row.index}
            columnId="description"
            updateMyData={updateMyData}
            isEditMode={isEditMode}
            inputType="text" // Text input for Description
            autoFocusField={
              props.row.index === 0 && props.column.id === "description"
            } // Set focus on the first "Description" field
          />
        ),
        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "text", accessor: "description" }} // Add accessor for description
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "primaryCategory",
        Header: "Primary Category",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          className: "text-wrap",
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Cell: (props) => (
          <EditableCell
            {...props}
            rowIndex={props.row.index}
            columnId="primaryCategory"
            updateMyData={updateMyData}
            isEditMode={isEditMode}
            inputType="dropdown" // Dropdown for Primary Category
            options={primaryCategories} // Dropdown options
            handlePrimaryCategoryChange={handlePrimaryCategoryChange} // Pass the function to handle primary category change
            toSetSecondaryCategoriesByPrimaryCategory={
              toSetSecondaryCategoriesByPrimaryCategory
            } // Pass the function to populate secondary categories
          />
        ),
        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "text", accessor: "primaryCategory" }} // Add accessor for primaryCategory
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "secondaryCategory",
        Header: "Secondary Category",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          className: "text-wrap",
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Cell: (props) => {
          const { row, updateMyData } = props;
          const primaryCategory = row.original.primaryCategory;
          const secondaryCategory = row.original.secondaryCategory;
          const emissionFactorId = row.original.emissionFactorId;

          // Fetch the secondary options based on the selected primary category
          const secondaryOptions =
            primaryCategory && primaryCategory !== ""
              ? toSetSecondaryCategoriesByPrimaryCategory(primaryCategory)
              : []; // If no primary category, set secondaryOptions to an empty array

          // Find the actual secondary category name using the emissionFactorId
          const selectedSecondaryCategory = secondaryOptions
            .flatMap((group) => group.categories)
            .find((cat) => cat._id === emissionFactorId);

          if (!isEditMode) {
            // In view mode, display the secondary category name
            return (
              <div>
                {selectedSecondaryCategory
                  ? selectedSecondaryCategory.activity_name
                  : secondaryCategory}
              </div>
            );
          }

          // In edit mode, render the dropdown with secondary category options
          return (
            <EditableCell
              {...props}
              rowIndex={row.index}
              columnId="secondaryCategory"
              emissionFactorId={emissionFactorId}
              updateMyData={updateMyData}
              handleSecondaryCategoryChange={handleSecondaryCategoryChange}
              isEditMode={isEditMode}
              inputType="dropdown"
              secondaryOptions={secondaryOptions} // Pass the dynamically updated options
            />
          );
        },

        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "text", accessor: "secondaryCategory" }} // Add accessor for secondaryCategory
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "dataSource",
        Header: "Data Source",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Cell: (props) => {
          const { dataSource, mainId } = props.row.original;

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Editable dropdown for dataSource */}
              <EditableCell
                {...props}
                rowIndex={props.row.index}
                columnId="dataSource"
                updateMyData={updateMyData}
                isEditMode={isEditMode}
                inputType="dropdown" // Dropdown for Data Source
                options={["Xero", "MYOB", "CSV", "Bill"]} // Dropdown options
              />

              {/* Display PDF icon if dataSource is Bill */}
              {dataSource === "Bill" && (
                <FontAwesomeIcon
                  icon={faFilePdf}
                  onClick={() => fetchBillUrl(mainId)} // Open the bill on click
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
              )}
            </div>
          );
        },
        Filter: (column) => (
          <FilterBox
            column={{
              ...column,
              type: "dataSourceOptions",
              accessor: "dataSource",
            }}
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "scope",
        Header: "Scope",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Cell: (props) => (
          <EditableCell
            {...props}
            rowIndex={props.row.index}
            columnId="scope"
            updateMyData={updateMyData}
            isEditMode={isEditMode}
            inputType="dropdown" // Dropdown for Scope
            options={["SCOPE1", "SCOPE2", "SCOPE3"]} // Dropdown options
          />
        ),
        Filter: (column) => (
          <FilterBox
            column={{ ...column, type: "scopeOptions", accessor: "scope" }} // Add accessor for scope
            filters={filters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "inclusionStatus",
        Header: "Status",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          className: "text-wrap",
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Cell: (props) => (
          <EditableCell
            {...props}
            rowIndex={props.row.index}
            columnId="inclusionStatus"
            updateMyData={updateMyData}
            isEditMode={isEditMode}
            inputType="dropdown" // Dropdown for Status
            options={["INCLUDED", "EXCLUDED"]} // Dropdown options for inclusion status
          />
        ),
        Filter: (column) => (
          <FilterBox
            column={{
              ...column,
              type: "statusOptions",
              accessor: "inclusionStatus",
            }} // Add accessor for inclusionStatus
            filters={filters}
            setFilters={setFilters}
            // setSearchValue={setSearchValue}
          />
        ),
      },
      {
        accessor: "reason",
        Header: "Exclusion rule",
        headerProps: {
          style: {
            minWidth: "200px",
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        cellProps: {
          className: "text-wrap",
          style: {
            textTransform: "capitalize",
            textAlign: "center",
          },
        },
        Cell: (props) => (
          <EditableCell
            {...props}
            rowIndex={props.row.index}
            columnId="reason"
            updateMyData={updateMyData}
            isEditMode={isEditMode}
            inputType="text" // Text input for Exclusion Rule
            // autoFocusField={
            //   props.row.index === 0 && props.column.id === "reason"
            // } // Set focus on the first "Reason" field
          />
        ),
        disableFilters: true, // Disable filters for this column
      },
    ],
    [filters, setFilters, isEditMode, updateMyData]
  );

  async function updateChartData() {
    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      startDate,
      endDate
    );

    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);

    toast.success("Data updated successfully");
  }

  async function updateGroupedUnifiedData(data, isExclusion = false) {
    let date = data.date;
    let emissions = data.emissions;

    // Replace the day with 01 (group by month)
    let newDate = date.slice(0, 8) + "01";

    // Get a reference to the GroupedUnifiedData collection
    const groupedDataCollection = collection(
      fireStoreDB,
      "GroupedUnifiedData",
      activeUser.businessId,
      "Groups"
    );

    // Look for a document in GroupedUnifiedData with the same date, primaryCategory, secondaryCategory, scope, and dataSource
    let groupedDataQuery = query(
      groupedDataCollection,
      where("date", "==", newDate),
      where("primaryCategory", "==", data.primaryCategory),
      where("secondaryCategory", "==", data.secondaryCategory),
      where("scope", "==", data.scope),
      where("dataSource", "==", data.dataSource)
    );

    // Additional conditions based on dataSource
    if (data.dataSource === "Bill") {
      groupedDataQuery = query(
        groupedDataQuery,
        where("contactName", "==", data.contactName),
        where("officeId", "==", data.officeId)
      );
    } else if (data.dataSource !== "Form") {
      groupedDataQuery = query(
        groupedDataQuery,
        where("contactName", "==", data.contactName),
        where("accountName", "==", data.accountName)
      );
    } else if (data.dataSource === "Form") {
      groupedDataQuery = query(
        groupedDataQuery,
        where("officeId", "==", data.officeId)
      );
    }

    // Execute the query and get the result
    const groupedDataSnapshot = await getDocs(groupedDataQuery);

    // If document exists, update the emissions
    if (groupedDataSnapshot.docs.length > 0) {
      const docData = groupedDataSnapshot.docs[0].data();
      const groupedDataDocRef = groupedDataSnapshot.docs[0].ref;

      // Emissions adjustment: either subtract or add based on inclusion/exclusion
      let emissionAdjustment = emissions;
      // Special case for Form dataSource: divide by 1000
      if (
        data.dataSource === "Form" &&
        !["Waste", "Paper", "Water"].includes(data.primaryCategory)
      ) {
        emissionAdjustment = emissions / 1000;
      }

      // Exclusion case: subtract emissions
      if (isExclusion) {
        // Calculate total emissions after decrement
        const totalEmissionsAfterDecrement =
          docData.totalEmissions - emissionAdjustment;

        if (totalEmissionsAfterDecrement <= 0) {
          // Delete the document if emissions drop to zero or below
          await deleteDoc(groupedDataDocRef);
          console.log("Document deleted due to zero or negative emissions.");
        } else {
          // Update the existing document with the adjusted emissions
          await updateDoc(groupedDataDocRef, {
            totalEmissions: totalEmissionsAfterDecrement,
          });
          console.log("Document updated with new emissions after exclusion.");
        }
      } else {
        // Inclusion case: add emissions to the existing document
        const totalEmissionsAfterIncrement = docData.totalEmissions + emissions;
        await updateDoc(groupedDataDocRef, {
          totalEmissions: totalEmissionsAfterIncrement,
        });
        console.log("Document updated with new emissions after inclusion.");
      }
    } else if (!isExclusion) {
      // If no document exists and it's an inclusion, create a new document
      await addDoc(groupedDataCollection, {
        date: newDate,
        accountName: data.accountName,
        categoryMatchingSource: data.categoryMatchingSource,
        consumption: data.consumption,
        primaryCategory: data.primaryCategory,
        primaryEmissionFactor: data.primaryEmissionFactor,
        secondaryCategory: data.secondaryCategory,
        secondaryEmissionFactor: data.secondaryEmissionFactor,
        scope: data.scope,
        score: data.score,
        dataSource: data.dataSource,
        totalEmissions: emissions,
        officeId: data.dataSource === "Form" ? data.officeId : "", // Include officeId for Form data
        contactId: data.dataSource !== "Form" ? data.contactId : "", // Include contactId for non-Form data
        contactName: data.dataSource !== "Form" ? data.contactName : "", // Include contactName for non-Form data
      });
      console.log("New document created with emissions after inclusion.");
    }
  }

  async function updateGroupedUnifiedDataByContactName(
    data,
    isChanged,
    oldPrimaryCategory,
    oldSecondaryCategory,
    oldScope,
    oldDataSource,
    oldInclusionStatus
  ) {
    let date = data.date;
    let emissions = data.emissions;

    // Replace the day with 01 to standardize for grouping by month
    let newDate = date.slice(0, 8) + "01";

    const groupedDataCollection = collection(
      fireStoreDB,
      "GroupedUnifiedData",
      activeUser.businessId,
      "Groups"
    );

    // Check if any relevant field has changed
    if (isChanged) {
      // Adjust emissions calculation based on dataSource
      let previousCategoryEmissions = data.previousEmissions || emissions;

      if (data.dataSource === "Form") {
        // Special handling for Form dataSource and certain categories
        if (
          data.primaryCategory !== "Waste" &&
          data.primaryCategory !== "Paper" &&
          data.primaryCategory !== "Water"
        ) {
          previousCategoryEmissions /= 1000;
        }
      }

      // Decrement emissions from the previous category
      let decrementQuery = query(
        groupedDataCollection,
        where("date", "==", newDate),
        where("primaryCategory", "==", oldPrimaryCategory),
        where("secondaryCategory", "==", oldSecondaryCategory),
        where("scope", "==", oldScope),
        where("dataSource", "==", oldDataSource)
      );

      // Add additional conditions based on dataSource
      if (data.dataSource === "Bill") {
        decrementQuery = query(
          decrementQuery,
          where("contactName", "==", data.contactName),
          where("officeId", "==", data.officeId)
        );
      } else if (data.dataSource !== "Form") {
        decrementQuery = query(
          decrementQuery,
          where("contactName", "==", data.contactName),
          where("accountName", "==", data.accountName)
        );
      } else if (data.dataSource === "Form") {
        decrementQuery = query(
          decrementQuery,
          where("officeId", "==", data.officeId)
        );
      }
      const decrementSnapshot = await getDocs(decrementQuery);

      if (decrementSnapshot.docs.length > 0) {
        await Promise.all(
          decrementSnapshot.docs.map(async (docSnapshot) => {
            const docData = docSnapshot.data();
            const totalEmissionsAfterDecrement =
              docData.totalEmissions - previousCategoryEmissions;

            if (totalEmissionsAfterDecrement <= 0) {
              // Delete the document if emissions drop to zero
              await deleteDoc(docSnapshot.ref);
            } else {
              // Otherwise, update the document with the new emissions total
              await updateDoc(docSnapshot.ref, {
                totalEmissions: totalEmissionsAfterDecrement,
              });
            }
          })
        );
      }
    }

    // Adjust emissions calculation for increment based on dataSource
    if (data.dataSource === "Form") {
      // Special handling for Form dataSource and certain categories
      if (
        data.primaryCategory !== "Waste" &&
        data.primaryCategory !== "Paper" &&
        data.primaryCategory !== "Water"
      ) {
        emissions /= 1000;
      }
    }

    // Increment emissions to the new or updated category
    let incrementQuery = query(
      groupedDataCollection,
      where("date", "==", newDate),
      where("primaryCategory", "==", data.primaryCategory),
      where("secondaryCategory", "==", data.secondaryCategory),
      where("scope", "==", data.scope),
      where("dataSource", "==", data.dataSource)
    );

    // Add additional conditions based on dataSource
    if (data.dataSource === "Bill") {
      incrementQuery = query(
        incrementQuery,
        where("contactName", "==", data.contactName),
        where("officeId", "==", data.officeId)
      );
    } else if (data.dataSource !== "Form") {
      incrementQuery = query(
        incrementQuery,
        where("contactName", "==", data.contactName),
        where("accountName", "==", data.accountName)
      );
    } else if (data.dataSource === "Form") {
      incrementQuery = query(
        incrementQuery,
        where("officeId", "==", data.officeId)
      );
    }

    const incrementSnapshot = await getDocs(incrementQuery);

    if (incrementSnapshot.docs.length > 0) {
      await Promise.all(
        incrementSnapshot.docs.map(async (docSnapshot) => {
          const docData = docSnapshot.data();
          const totalEmissionsAfterIncrement =
            docData.totalEmissions + emissions;

          // Update the document with the incremented emissions
          await updateDoc(docSnapshot.ref, {
            totalEmissions: totalEmissionsAfterIncrement,
          });
        })
      );
    } else {
      // If no document exists, create a new one
      await addDoc(groupedDataCollection, {
        date: newDate,
        accountName: data.accountName,
        categoryMatchingSource: data.categoryMatchingSource,
        consumption: data.consumption,
        primaryCategory: data.primaryCategory,
        primaryEmissionFactor: data.primaryEmissionFactor,
        secondaryCategory: data.secondaryCategory,
        secondaryEmissionFactor: data.secondaryEmissionFactor,
        scope: data.scope,
        score: data.score,
        dataSource: data.dataSource,
        totalEmissions: emissions,
        officeId: data.dataSource === "Form" ? data.officeId : "", // Include officeId for Form data
        contactId: data.dataSource !== "Form" ? data.contactId : "", // Include contactId for non-Form data
        contactName: data.dataSource !== "Form" ? data.contactName : "", // Include contactName for non-Form data
      });
    }
  }

  //handle bulk actions
  async function handleBulkActions(actionType) {
    const userData = JSON.parse(getActiveUserData());
    let updatedBy = userData.displayName;

    if (isImpersonationModeEnabled()) {
      const parentUserData = JSON.parse(getUserData());
      updatedBy = parentUserData.displayName;
    }

    try {
      const batch = writeBatch(fireStoreDB);

      // Gather the ids of the selected rows based on the filtered data
      const selectedRowIdsList = Object.keys(selectedRowIds).map(
        (rowId) => filteredData[rowId].id
      );

      const updateData = {
        inclusionStatus: actionType === "EXCLUDE" ? "EXCLUDED" : "INCLUDED",
        reason: actionType === "EXCLUDE" ? `Excluded by ${updatedBy}` : "",
      };

      const updatedUnifiedData = unifiedData.map((row) => {
        if (selectedRowIdsList.includes(row.id)) {
          const unifiedDataDocRef = doc(
            fireStoreDB,
            "UnifiedData",
            activeUser.businessId,
            "DataLines",
            row.id
          );
          batch.update(unifiedDataDocRef, updateData);

          // Return the updated row with inclusion/exclusion change
          return { ...row, ...updateData };
        }
        return row; // Return unchanged row if not selected
      });

      await batch.commit();
      toast.success(
        actionType === "EXCLUDE"
          ? "Selected rows have been excluded"
          : "Selected rows have been included"
      );

      // Update unifiedData locally without full re-render
      setUnifiedData((prevData) =>
        prevData.map((row) => {
          if (selectedRowIdsList.includes(row.id)) {
            // Update the selected rows based on the action type
            return { ...row, ...updateData };
          }
          return row;
        })
      );

      // Update filteredData for UI consistency, only affecting filtered rows
      setFilteredData((prevData) =>
        prevData.map((row) => {
          if (selectedRowIdsList.includes(row.id)) {
            // Update the filtered rows based on the action type
            return { ...row, ...updateData };
          }
          return row;
        })
      );

      // Update grouped unified data
      await Promise.all(
        selectedRowIdsList.map(async (rowId) => {
          const row = unifiedData.find((data) => data.id === rowId);
          await updateGroupedUnifiedData(row, actionType === "EXCLUDE");
        })
      );

      // Optionally, update chart data if required
      if (unifiedDataCalCompleted) {
        updateChartData();
      }

      // Clear selected rows after the bulk action
      setSelectedRowIds({});
    } catch (error) {
      console.log(`Error during bulk ${actionType.toLowerCase()}:`, error);
    }
  }

  async function handleEditSelectedAccountNamesClose(isRefresh) {
    setShowEditSelectedAccountNamesModal(false);
    if (isRefresh) {
      await getUnifiedData();
    }
  }

  async function getUnifiedData() {
    let unifiedData = [];
    // one day before the start date to include the start date
    const startDateString = `${formatDateToYYYYMMDD(
      new Date(startDate.setDate(startDate.getDate() - 1))
    )}T10:00:00.000Z`;
    const endDateString = `${formatDateToYYYYMMDD(
      new Date(endDate)
    )}T00:00:00.000Z`;

    try {
      const qry = query(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        ),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString)
      );
      const querySnapshot = await getDocs(qry);
      querySnapshot.forEach((doc) => {
        unifiedData.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      if (unifiedData.length > 0) {
        // Always sort the data by date (most recent first)
        unifiedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        // unifiedData = toFetchDateFilteredData(unifiedData);

        //formatting data
        unifiedData.map((e) => {
          //formatting emissions to 2 decimal places
          // e.emissions = parseFloat(e.emissions * 1000).toFixed(2);
          //formatting date to dd/mm/yyyy
          e.dateTime = new Date(e.date);
        });
      }
    } catch (error) {
      console.log(error);
    }
    setUnifiedData(unifiedData);
  }

  async function fetchNetnadaFactorsFromMongoDB() {
    const contryName = getFirstCountry();
    const targetUrl = `https://netnada-factor-production-8ed52ccdfd5e.herokuapp.com/factors/allGroupByCategoryAndCountry/${contryName}`;
    try {
      const response = await fetch(targetUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_NETNADA_API_KEY,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const data = await response.json();
      // console.log("data", data);

      return data;
    } catch (error) {
      console.error("Failed to fetch emission factors:", error);
      return null;
    }
  }

  async function get() {
    await getUnifiedData();

    let data = await fetchNetnadaFactorsFromMongoDB();

    //omit the category_name whose activities array has no item with unit as '$', except for category_name="Electricity" and "Stationary energy (gaseous fuels)"
    // const filteredData = data.filter((item) => {
    //   if (
    //     item.category_name !== "Electricity" &&
    //     item.category_name !== "Stationary energy (gaseous fuels)"
    //   ) {
    //     const activities = item.activities;
    //     // Check if activities array has no item with unit as '$'
    //     const hasDollar = activities.some((activity) => activity.unit === "$");
    //     return hasDollar;
    //   }
    //   return true; // Keep "Electricity" and "Stationary energy (gaseous fuels)"
    // });

    //replace the category_name with "Working from home" with "WFH"
    const filteredData = data.map((item) => {
      if (item.category_name === "Working from home") {
        item.category_name = "WFH";
      }
      return item;
    });
    // console.log("filteredData=>", filteredData);

    //fetch all the primary and secondary categories
    let primaryCategories = [];
    let combinedCategoryFactorsObj = {};
    for (let i = 0; i < filteredData.length; i++) {
      let factorObject = filteredData[i];
      let primaryCategory = factorObject.category_name;
      primaryCategories.push(primaryCategory);
      combinedCategoryFactorsObj[primaryCategory] = factorObject["activities"];
    }

    // console.log("primaryCategories=>", primaryCategories);
    // console.log("combinedCategoryFactorsObj=>", combinedCategoryFactorsObj);

    setPrimaryCategories(primaryCategories);
    setCombinedCategoryFactorsObj(combinedCategoryFactorsObj);
    setIsLoading(false);
  }

  // fetch unified data
  useEffect(() => {
    get();
  }, [activeUser, startDate, endDate]);

  //fetch accounts list
  useEffect(() => {
    const fetchAccountsList = async () => {
      //check if a data source exists
      const csvPresent = JSON.parse(sessionStorage.getItem("csvPresent"));
      const dataSource = csvPresent ? "CSV" : activeUser.authProvider;
      if (!dataSource) return;

      //fetch accounts list from firebase
      const querySnapshot = await getDocs(
        collection(fireStoreDB, "Accounts", activeUser.businessId, dataSource)
      );
      let accountsList = [];
      querySnapshot.forEach((doc) => {
        const data = JSON.parse(JSON.stringify(doc.data()));
        if (activeUser.authProvider === "Xero") {
          if (!EXCLUDE_ACCOUNT_TYPES_XERO.includes(data.type)) {
            accountsList.push(data);
          }
        }
        if (activeUser.authProvider === "MYOB" || csvPresent) {
          accountsList.push(data);
        }
      });
      // console.log("accountList=>", accountsList);
      if (activeUser.authProvider === "Xero") {
        //sort accountsList by name
        accountsList = accountsList.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }
      if (activeUser.authProvider === "MYOB") {
        //sort accountsList by DisplayID
        accountsList = accountsList.sort((a, b) => {
          // Extract the DisplayID values from the account objects
          const displayIDA = a.DisplayID;
          const displayIDB = b.DisplayID;

          // Use the localeCompare method to perform a string comparison
          return displayIDA.localeCompare(displayIDB);
        });
      }
      if (csvPresent) {
        accountsList = accountsList.sort((a, b) => {
          // Extract the DisplayID values from the account objects
          const nameA = a.name;
          const nameB = b.name;

          // Use the localeCompare method to perform a string comparison
          return nameA.localeCompare(nameB);
        });
      }
      setAccountsList(accountsList);
    };

    fetchAccountsList();
  }, []);

  //apply filters
  useEffect(() => {
    const newFilteredData = applyFilters(unifiedData, filters);
    setFilteredData(newFilteredData);
  }, [unifiedData, filters, searchBy, searchValue]);

  return (
    <>
      {showFrozenDataModal && (
        <FrozenDataModal
          show={showFrozenDataModal}
          onHide={() => {
            setShowFrozenDataModal(false);
          }}
        />
      )}

      {showEditSelectedAccountNamesModal && (
        <EditSelectedAccountNamesModal
          showEditSelectedAccountNamesModal={showEditSelectedAccountNamesModal}
          handleEditSelectedAccountNamesClose={
            handleEditSelectedAccountNamesClose
          }
          accountsList={accountsList}
          setAccountsList={setAccountsList}
        />
      )}

      {showBillModal && (
        <FileViewModal
          showBillModal={showBillModal}
          setShowBillModal={setShowBillModal}
          activeUser={activeUser}
          billId={billId}
          billUrl={billUrl}
        />
      )}

      {showWarningModal && (
        <WarningModal
          showWarningModal={showWarningModal}
          handleWarningClose={() => setShowWarningModal(false)}
          handleDiscardChanges={handleDiscardChanges}
          handleSaveChanges={handleSaveChanges}
        />
      )}

      {isLoading ? (
        <div className="mb-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={filteredData}
            sortable
            pagination
            perPage={25}
            rowCount={filteredData.length}
          >
            <Card>
              <Card.Header>
                <AllTransactionsHeader
                  isDataFrozen={isDataFrozen}
                  setShowFrozenDataModal={setShowFrozenDataModal}
                  setShowEditSelectedAccountNamesModal={
                    setShowEditSelectedAccountNamesModal
                  }
                  selectedRowIds={selectedRowIds}
                  handleBulkActions={handleBulkActions}
                  isEditMode={isEditMode}
                  toggleEditMode={toggleEditMode}
                  unsavedChanges={unsavedChanges}
                  saveChanges={saveChanges}
                />
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                  showHeaderRow={true}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={unifiedData}
                  rowCount={unifiedData.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
    </>
  );
};

export default AllTransactionsTable;
