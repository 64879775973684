import { act, useEffect, useMemo, useState } from "react";
import {
  addMeasurementYear,
  createStepsProgress,
  getEmployeeCount,
  getStepsProgressByInventoryId,
  getUserBusinessLocations,
} from "../new-overview/newOverviewAPI";
import {
  getActiveUserData,
  getInventoryProgress,
  getStepsProgress,
  setActiveUserData,
  setInventoryProgress,
  setStepsProgress,
} from "../../helpers/store";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";
import { toast } from "react-toastify";
import CategoryCard from "./CategoryCard";
import HomepageHeader from "./HomepageHeader";
import GoalCard from "./GoalCard";
import LoadingCard from "./LoadingCard";
import NewReportingPeriodModal from "../new-overview/NewReportingPeriodModal";
import {
  faChessRook,
  faVideo,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from "firebase/firestore";
import app from "../../firebase";
import { SideCard } from "./SideCard";

const fireStoreDB = getFirestore(app);

const dataSample = [
  {
    title: "Support Hub",
    text: "Learn about sustainability and how to use NetNada",
    icon: faChessRook,
    color: "primary",
    link: "https://netnada.gitbook.io/netnada",
  },
  // {
  //   title: "Engage your supply chain",
  //   text: "Understand who you buy from and improve your sustainability",
  //   icon: faCrown,
  //   color: "warning",
  //   link: "mailto:support@netnada.com.au?subject=We%20are%20ready%20to%20engage%20our%20suppliers!&body=We%20understand%20that%20supplier%20engagement%20is%20an%20important%20step%20in%20any%20sustainability%20journey.%20We%20would%20love%20your%20help%20to%20better%20understand%20our%20supply%20chain%20and%20what%20we%20can%20do%20to%20improve%20it.%20If%20you%20can%20get%20back%20to%20me%20with%20a%20time%20to%20meet%20and%20discuss%20that%20would%20be%20ideal.%20Thank%20you!",
  // },
  {
    title: "Book a meeting",
    text: "Need help? Reach out to us",
    icon: faVideo,
    color: "success",
    // link: "https://calendly.com/netnada/customer-support?month=2023-11",
    link: "https://meetings.hubspot.com/alex-king4",
  },
  {
    title: "Invite your team",
    text: "Teamwork makes the dream work!",
    icon: faUser,
    color: "info",
    link: "/welcome/inviteUser",
  },
];

export default function Overview() {
  const [activeUser, setActiveUser] = useState(JSON.parse(getActiveUserData()));
  const [openCategory, setOpenCategory] = useState(null);
  const [stepsProgressData, setStepsProgressData] = useState(
    JSON.parse(getStepsProgress())
  );
  // console.log(stepsProgressData);
  const [isLoading, setIsLoading] = useState(false);
  const [measurementYearSet, setMeasurementYearSet] = useState(
    activeUser.measurementYearSet
  );
  const [measurementYear, setMeasurementYear] = useState(
    activeUser.selectedMeasurementYear +
      "-" +
      activeUser.selectedMeasurementYearType
  );
  const [emissionsBoundariesSet, setEmissionsBoundariesSet] = useState(
    activeUser.emissionBoundariesSet
  );
  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(
    () => {
      const inventoryProgress = getInventoryProgress();
      return inventoryProgress ? JSON.parse(inventoryProgress) : [];
    }
  );
  // const selectedInventoryProgress = currentInventoryProgress.find((item) => {
  //   return (
  //     item.measurementyear === activeUser.selectedMeasurementYear &&
  //     item.measurementyeartype === activeUser.selectedMeasurementYearType
  //   );
  // });
  const [selectedInventoryProgress, setSelectedInventoryProgress] = useState(
    []
  );

  //set selected inventory progress if current inventory progress changes
  useEffect(() => {
    if (currentInventoryProgress.length === 0) return;
    const selectedInventoryProgress = currentInventoryProgress.find((item) => {
      return (
        item.measurementyear === activeUser.selectedMeasurementYear &&
        item.measurementyeartype === activeUser.selectedMeasurementYearType
      );
    });
    setSelectedInventoryProgress(selectedInventoryProgress);
  }, [currentInventoryProgress, stepsProgressData]);

  const [isNewPeriodModalOpen, setIsNewPeriodModalOpen] = useState(
    !activeUser.inventoryId ? true : false
  );

  const currentSubStep = useMemo(() => {
    console.log(
      "Run useMemo as we have new inv progress and fecth inv progress current Step"
    );
    console.log("selectedInventoryProgress", selectedInventoryProgress);
    return selectedInventoryProgress?.currentStepProgressId;
  }, [selectedInventoryProgress]);
  // console.log("stepsProgressData", stepsProgressData);

  const createStepSaveStateUpdateCurrentStep = async (autocomplete = false) => {
    const body = {
      userId: activeUser.uid,
      inventoryId: activeUser.inventoryId,
      autocomplete: autocomplete,
    };

    //If before 2023 financial year we need to mark it all as done
    const stepsProgressResponse = await createStepsProgress(body);

    // console.log(
    //   "stepsProgressResponse",
    //   stepsProgressResponse.data.stepsProgress
    // );

    //Save the next task in local storgare and variable -> stepsProgressResponse.data.nextTask
    const nextTask = stepsProgressResponse.data.nextTask;

    setStepsProgress(JSON.stringify(stepsProgressResponse.data.stepsProgress));
    setStepsProgressData(stepsProgressResponse.data.stepsProgress);

    const updatedInventoryProgress = currentInventoryProgress.map((item) => {
      if (item.inventoryid === activeUser.inventoryId) {
        return {
          ...item,
          currentStepProgressId: nextTask,
        };
      }
      return item;
    });
    setCurrentInventoryProgress(updatedInventoryProgress);
    setInventoryProgress(JSON.stringify(updatedInventoryProgress));
  };

  const onMeasurementYearSelection = async (reportingYear) => {
    setIsLoading(true);

    const year = reportingYear.split(" ")[0];
    const yearType = reportingYear.split(" ")[1];

    //update this flag to false so that unified data can be recalculated
    sessionStorage.setItem("dashboardDataCalCompleted", false);
    // console.log("year", year, yearType);
    setMeasurementYear(reportingYear);
    //get inventory progress for the selected year
    // setIsLoadingInventoryProgress(true);

    const selectedInventoryProgress = currentInventoryProgress.filter(
      (item) =>
        item.measurementyear === year &&
        item.measurementyeartype === yearType.toLowerCase()
    );
    console.log(
      "selectedInventoryProgress on change year",
      selectedInventoryProgress
    );
    //update user data

    activeUser.inventoryId = selectedInventoryProgress[0].inventoryid;
    activeUser.selectedMeasurementYear =
      selectedInventoryProgress[0].measurementyear;
    activeUser.selectedMeasurementYearType =
      selectedInventoryProgress[0].measurementyeartype;
    activeUser.currentStartDate = selectedInventoryProgress[0].currentStartDate;
    activeUser.currentEndDate = selectedInventoryProgress[0].currentEndDate;
    activeUser.emissionBoundariesSet =
      selectedInventoryProgress[0].emissionBoundariesSet;
    activeUser.dateRangeStart = selectedInventoryProgress[0].dateRangeStart;
    activeUser.dateRangeEnd = selectedInventoryProgress[0].dateRangeEnd;
    activeUser.isFrozen = selectedInventoryProgress[0].isFrozen;
    activeUser.showPreviousYear = selectedInventoryProgress[0].showPreviousYear;
    activeUser.totalEmissions = selectedInventoryProgress[0].totalEmissions;

    sessionStorage.setItem("dashboardDataCalCompleted", false);
    setActiveUserData(JSON.stringify(activeUser));
    setActiveUser(activeUser);
    // Fetch steps_progress data for the selected inventoryId

    try {
      const stepsProgressData = await getStepsProgressByInventoryId(
        activeUser.inventoryId
      );
      // console.log("FETCHED", stepsProgressData);

      //For legacy users who do not have steps progress data in that year
      if (!stepsProgressData || stepsProgressData.categories.length === 0) {
        let autocomplete = false;
        if (year < 2023) {
          autocomplete = true;
          console.log("Autocomplete", autocomplete);
        } else if (year === 2023 && yearType.toLowerCase() === "financial") {
          autocomplete = true;
          console.log("Autocomplete", autocomplete);
        }

        await createStepSaveStateUpdateCurrentStep(autocomplete);
      } else {
        // Store it in local storage
        setStepsProgress(JSON.stringify(stepsProgressData));
        setStepsProgressData(stepsProgressData);
        // Update the current step progress
      }
    } catch (error) {
      console.error("Error fetching steps progress data:", error);
      // Handle the error as needed, e.g., show a notification to the user
    }

    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      activeUser.currentStartDate,
      activeUser.currentEndDate
    );
    setEmissionsBoundariesSet(
      selectedInventoryProgress[0].emissionBoundariesSet
    );

    // setIsLoadingInventoryProgress(false);
    setIsLoading(false);
    toast("Reporting year changed!", { type: "success" });
  };

  async function toAddMeasurementYear(
    measurementYear,
    measurementType,
    newUser
  ) {
    setIsLoading(true);
    // Set loading state if needed
    // When I add a new measurement year I should also copy the company details
    const existingInventoryProgress = JSON.parse(getInventoryProgress()) || [];
    const isDuplicate = existingInventoryProgress.some(
      (inv) =>
        inv.measurementyear === measurementYear &&
        inv.measurementyeartype === measurementType
    );

    if (!isDuplicate) {
      const body = {
        measurementYear,
        measurementType,
        newUser,
      };
      const response = await addMeasurementYear(body);
      const inventoryProgress = response.data.inventoryProgress;
      // console.log("inventoryProgress", inventoryProgress.progress);
      if (response.status === "success") {
        //Set Progress Data
        setStepsProgress(JSON.stringify(inventoryProgress.progress));
        setStepsProgressData(inventoryProgress.progress);

        // Update user data
        const activeUser = JSON.parse(getActiveUserData());
        activeUser.inventoryId = inventoryProgress.inventoryid;
        activeUser.measurementYearSet = true;
        activeUser.selectedMeasurementYear = inventoryProgress.measurementyear;
        activeUser.selectedMeasurementYearType =
          inventoryProgress.measurementyeartype;
        activeUser.currentStartDate = inventoryProgress.currentStartDate;
        activeUser.currentEndDate = inventoryProgress.currentEndDate;
        activeUser.dateRangeStart = inventoryProgress.dateRangeStart;
        activeUser.dateRangeEnd = inventoryProgress.dateRangeEnd;
        setActiveUserData(JSON.stringify(activeUser));
        setActiveUser(activeUser);
        setEmissionsBoundariesSet(false);

        // If setting reporting year first time
        if (!measurementYearSet) {
          // Update state
          setCurrentInventoryProgress([inventoryProgress]);
          // Update inventory progress
          setInventoryProgress(JSON.stringify([inventoryProgress]));
        } else {
          // If setting consecutive reporting year
          // Update state
          setCurrentInventoryProgress((prevState) => [
            inventoryProgress,
            ...prevState,
          ]);
          // Update inventory progress
          const inventoryProgressList = JSON.parse(getInventoryProgress());
          inventoryProgressList.unshift(inventoryProgress);
          setInventoryProgress(JSON.stringify(inventoryProgressList));
        }

        // Update flag to recalculate unified data
        setMeasurementYear(measurementYear + " " + measurementType);
        setMeasurementYearSet(true);
        sessionStorage.setItem("dashboardDataCalCompleted", false);

        toast("Welcome to new reporting year!", { type: "success" });
      } else {
        toast("Error adding measurement year", { type: "error" });
      }
    } else {
      toast("Measurement year already exists!", { type: "error" });
    }
    setIsLoading(false);
  }

  async function getBusinessOffice() {
    const response = await getUserBusinessLocations();

    //store all offices id in session as string
    let offices = [];
    for (let i in response) offices.push(response[i]["office_id"]);
    sessionStorage.setItem("officeId", JSON.stringify(offices));

    //store all location w.r.t to office id in session as string
    let locations = [];
    for (let i in response) locations.push(response[i]["location"]);
    sessionStorage.setItem("location", JSON.stringify(locations));

    //store all officeId,officeName in session as string
    let officeNameId = [];
    for (let i in response)
      officeNameId.push(
        response[i]["officename"] + "," + response[i]["office_id"]
      );
    sessionStorage.setItem("officeNameId", JSON.stringify(officeNameId));

    //find offices under same businessId
    let businessOffices = [];
    for (let i in response)
      businessOffices.push(
        response[i]["officename"] + "," + response[i]["office_id"]
      );
    sessionStorage.setItem("businessOffices", JSON.stringify(businessOffices));

    //store all office names in session as string
    let officeName = [];
    for (let i in response) officeName.push(response[i]["officename"]);
    sessionStorage.setItem("officeName", JSON.stringify(officeName));

    // Set all business locations in session
    sessionStorage.setItem("userBusinessLocations", JSON.stringify(response));

    //store officeId in state
    // setOffices(offices);
  }

  //check if unified data is present for the current business
  const checkUnifiedDataExistence = async () => {
    const qry = query(
      collection(
        fireStoreDB,
        "UnifiedData",
        activeUser.businessId,
        "DataLines"
      ),
      limit(1)
    );

    const querySnapshot = await getDocs(qry);
    return !querySnapshot.empty;
  };

  const checkUnifiedDataCSV = async () => {
    const qry = query(
      collection(
        fireStoreDB,
        "UnifiedData",
        activeUser.businessId,
        "DataLines"
      ),
      where("dataSource", "==", "CSV"),
      limit(1)
    );

    const querySnapshot = await getDocs(qry);
    return !querySnapshot.empty;
  };

  const checkUnifiedData = async () => {
    try {
      var csvPresent = await checkUnifiedDataCSV();
      var unifiedDataCalCompleted = false;

      if (csvPresent) {
        unifiedDataCalCompleted = true;
      } else {
        unifiedDataCalCompleted = await checkUnifiedDataExistence();
      }
      // // Parallel execution
      // const [unifiedDataCalCompleted, csvPresent] = await Promise.all([
      //   checkUnifiedDataExistence(),
      //   checkUnifiedDataCSV(),
      // ]);

      sessionStorage.setItem(
        "unifiedDataCalCompleted",
        unifiedDataCalCompleted
      );
      sessionStorage.setItem("csvPresent", csvPresent);
    } catch (error) {
      console.error("Error checking unified data:", error);
      throw error;
    }
  };

  async function get() {
    setIsLoading(true);
    //to check if registration is by
    if (sessionStorage.getItem("xero") !== undefined) {
      sessionStorage.setItem("xero", "true");
    }

    //if 'dashboardDataCalCompleted' is not present in session, then initialize it to false
    if (
      sessionStorage.getItem("dashboardDataCalCompleted") === undefined ||
      !sessionStorage.getItem("dashboardDataCalCompleted")
    ) {
      console.log("dashboardDataCalCompleted is not present in session");
      sessionStorage.setItem("dashboardDataCalCompleted", false);
    }

    // await getBusinessOffice();

    await checkUnifiedData();

    await removeUnifiedDateFromStore();

    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      activeUser.currentStartDate,
      activeUser.currentEndDate
    );
    setIsLoading(false);
    // await checkContentImageUploads();

    const userRole = activeUser.role;
    if (userRole !== "EMPLOYEE") {
      const employeeCount = await getEmployeeCount();
      sessionStorage.setItem("employeeCount", employeeCount);
    }
  }

  useEffect(() => {
    getBusinessOffice();
    if (!measurementYearSet) return;
    //if 'unifiedDataCalCompleted' is not present in session
    const unifiedDataCalCompleted = sessionStorage.getItem(
      "unifiedDataCalCompleted"
    );

    if (unifiedDataCalCompleted) {
      return;
    }
    get();
  }, [measurementYearSet]);

  const calculateProgress = (subSteps) => {
    if (!subSteps || subSteps.length === 0) {
      return 0; // Return 0 if there are no subSteps
    }

    const completedOrSkippedTasks = subSteps.filter(
      (task) =>
        task.status === "completed" ||
        task.status === "skipped" ||
        (Number(task.status) >= 1 && Number(task.status) <= 12)
    ).length;

    return (completedOrSkippedTasks / subSteps.length) * 100;
  };

  const calculateCategoryProgress = (category) => {
    if (!category || !category.steps || category.steps.length === 0) {
      return 0; // Return 0 if there are no steps or the category is undefined
    }

    const stepProgress = category.steps.map((step) =>
      calculateProgress(step.subSteps)
    );

    const validStepProgress = stepProgress.filter(
      (progress) => progress !== null && !isNaN(progress)
    );

    return validStepProgress.length > 0
      ? validStepProgress.reduce((a, b) => a + b, 0) / validStepProgress.length
      : 0;
  };

  const totalProgress =
    stepsProgressData?.categories?.length > 0
      ? stepsProgressData.categories.reduce(
          (sum, step) => sum + calculateCategoryProgress(step),
          0
        ) / stepsProgressData.categories.length
      : 0;

  const findCurrentSubStepData = (currentSubStep, stepsProgressData) => {
    if (
      !stepsProgressData ||
      !stepsProgressData.categories ||
      stepsProgressData.categories.length === 0
    ) {
      console.log("No steps progress data found");
      return {
        category: "Default Category",
        categoryIndex: 0,
        step: "Default Step",
        subStep: "Default SubStep",
        stepLocation: "2",
        url: "/",
      };
    }

    for (let i = 0; i < stepsProgressData.categories.length; i++) {
      const category = stepsProgressData.categories[i];

      // Check if the category has steps
      if (!category.steps || category.steps.length === 0) {
        continue; // Skip if no steps
      }

      for (let j = 0; j < category.steps.length; j++) {
        const step = category.steps[j];

        // Check if the step has subSteps
        if (!step.subSteps || step.subSteps.length === 0) {
          continue; // Skip if no subSteps
        }

        for (let k = 0; k < step.subSteps.length; k++) {
          if (step.subSteps[k].stringId === currentSubStep) {
            // Assuming currentSubStep is 1-based
            const subStep = step.subSteps[k];
            const stepLocation = `${i + 1}.${j + 1}.${k + 1}`;

            return {
              category: category.name,
              catIndex: i,
              step: step.name,
              stepIndex: j,
              subStep: subStep.name,
              selfClickable: subStep.selfClickable,
              skippable: subStep.skippable,
              stepLocation: stepLocation,
              stringId: subStep.stringId,
              officeId: subStep.officeId,
              url: subStep.url || "/", // Default to "/" if subStep.url is undefined
            };
          }
        }
      }
    }
    console.log("Default return if no valid subStep is found");
    // Default return if no valid subStep is found
    return {
      category: "Default Category",
      categoryIndex: 0,
      step: "Default Step",
      subStep: "Default SubStep",
      stepLocation: "2",
      url: "/",
    };
  };

  const currentSubStepData = useMemo(() => {
    return findCurrentSubStepData(currentSubStep, stepsProgressData);
  }, [currentSubStep, stepsProgressData]);

  // console.log("currentSubStepData", currentSubStepData);
  const handleContinue = (categoryIndex) => {
    // console.log("categoryIndex", categoryIndex);
    setOpenCategory(stepsProgressData?.categories[categoryIndex]?.name);
  };

  const handleCloseNewPeriodModal = () => {
    setIsNewPeriodModalOpen(false);
  };

  const handleAddNewPeriod = async (year, type, newUser) => {
    await toAddMeasurementYear(year, type, newUser);
    handleCloseNewPeriodModal();
  };

  // const toSetNextTask = async () => {
  //   const nextTask = await getNextProgressStepByInventoryId(
  //     activeUser.inventoryId
  //   );
  //   if (!nextTask) {
  //     return;
  //   }
  //   const updatedInventoryProgress = currentInventoryProgress.map((item) => {
  //     if (item.inventoryid === activeUser.inventoryId) {
  //       return {
  //         ...item,
  //         currentStepProgressId: nextTask,
  //       };
  //     }
  //     return item;
  //   });

  //   const currentStepBody = {
  //     inventoryProgressId: activeUser.inventoryId,
  //     stringId: nextTask,
  //   };
  //   await updateCurrentStepProgressInInventoryProgress(currentStepBody);

  //   setCurrentInventoryProgress(updatedInventoryProgress);
  //   setInventoryProgress(JSON.stringify(updatedInventoryProgress));
  // };

  useEffect(() => {
    //For legacy users who do not have steps progress data and appear in the hompegae when
    //they login
    if (stepsProgressData?.categories?.length === 0 && activeUser.inventoryId) {
      let autocomplete = false;
      if (+activeUser.selectedMeasurementYear < 2023) {
        autocomplete = true;
        console.log("Autocomplete", autocomplete);
      } else if (
        +activeUser.selectedMeasurementYear === 2023 &&
        activeUser.selectedMeasurementYearType.toLowerCase() === "financial"
      ) {
        autocomplete = true;
        console.log("Autocomplete", autocomplete);
      }
      console.log("run create steps");
      createStepSaveStateUpdateCurrentStep(autocomplete);
    }
    // } else {
    //   if (
    //     stepsProgressData?.categories?.length > 0 &&
    //     activeUser.inventoryId &&
    //     !currentSubStep
    //   ) {
    //     console.log("setting next task");
    //     //set next task
    //     toSetNextTask();
    //   }
    // }
  }, []);

  return (
    <>
      <div className="tw-container tw-mx-auto tw-p-4">
        <HomepageHeader
          measurementYear={measurementYear}
          currentInventoryProgress={currentInventoryProgress}
          onMeasurementYearSelection={onMeasurementYearSelection}
          onAddNewPeriod={toAddMeasurementYear}
        />
        <div className="tw-mx-auto">
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-12 tw-gap-6">
            <div className="tw-col-span-1 md:tw-col-span-9">
              <GoalCard
                totalProgress={totalProgress}
                nextTask={currentSubStepData}
                setStepsProgressData={setStepsProgressData}
                setCurrentInventoryProgress={setCurrentInventoryProgress}
              />
            </div>
            <div className="tw-col-span-1 md:tw-col-span-3">
              <SideCard links={dataSample} />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="tw-grid tw-gap-6 md:tw-grid-cols-2 lg:tw-grid-cols-2">
            {Array.from({ length: 5 }).map((_, index) => (
              <LoadingCard key={index} />
            ))}
          </div>
        ) : (
          <div className="tw-grid tw-gap-6 md:tw-grid-cols-2 lg:tw-grid-cols-2">
            {stepsProgressData?.categories?.map((category, index) => (
              <CategoryCard
                key={index}
                category={category}
                index={index}
                calculateCategoryProgress={calculateCategoryProgress}
                calculateProgress={calculateProgress}
                handleContinue={handleContinue}
                nextTask={currentSubStepData}
                openCategory={openCategory}
                setOpenCategory={setOpenCategory}
                setStepsProgressData={setStepsProgressData}
              />
            ))}
          </div>
        )}
      </div>
      {
        <NewReportingPeriodModal
          show={isNewPeriodModalOpen}
          onHide={handleCloseNewPeriodModal}
          onAddMeasurementYear={handleAddNewPeriod}
          forceAnswer={true}
        />
      }
    </>
  );
}
